$(function () {
    $(".switchLangBtn").on("click", function () {
        $(".switchLangWrapper").toggle();
    });

    var topAccount = $(".block_top_item.is_logged");
    var topCart = $("#cart_top");
    var topConnexion = $('.connexion_top');
    var topaCartDeatil = $('#show_top_cart');
    var menuShade = $('#shad_menu');

    $(topAccount).on('mouseenter touchstart', function (evt) {
        if (!topConnexion.hasClass("appear")) {
            $(this).addClass("underlined");
            $(".customerpage").addClass("bgSprite bfr logoBlue");
            evt.preventDefault();
            topConnexion.addClass("appear");
            topaCartDeatil.removeClass("appear");
            menuShade.addClass("actif");

        }
    });

    topAccount.on('mouseleave', function () {
        if (topConnexion.hasClass("appear")) {
            topConnexion.removeClass("appear");
            $(this).removeClass("underlined");
            $(".customerpage").removeClass("bgSprite bfr logoBlue");
            menuShade.removeClass("actif");

        }
    });

    topCart.on('mouseenter touchstart', function (evt) {
        evt.preventDefault();
        topCartAppear();
        $(this).unbind('touchstart');
    });

    topCart.on('mouseleave', function () {
        topCartDesappear();
    });

    $('#shad_menu').on('touchstart', function (evt) {
        topCartDesappear();
    });

    var menu_timeout = setTimeout(null);

    $(".mainMenu .main_menu_itm").not(".menu_itm_hover").on({
        mouseenter: function () {

            var mainmenu = $(".mainMenu");

            clearTimeout(menu_timeout);

            mainmenu.addClass("actif");
        },
        mouseleave: function () {

            menuItemClose();
        }
    });

    $(".mainMenu .menu_itm_hover").on("mouseenter touchstart", function (evt) {

        var mainmenu = $(".mainMenu");
        var submenu = $(".submenu_wrapper", this);

        clearTimeout(menu_timeout);

        if (!mainmenu.hasClass("actif")) {

            $(this).addClass("hovering bgSprite bfr logoBlue");

            menuShade.addClass("actif");

            mainmenu.addClass("actif");
        } else {

            if (!$(this).hasClass("hovering")) {

                $(this).addClass("hovering bgSprite bfr logoBlue");
            }
        }
    });

    // La gestion des bannières dans le menu par rayon
    $('.mainMenu .ss_cat_name').on('mouseover', function () {
        var data_visuel = $(this).attr('data-visuel');
        // Si il y a un visuel attaché à cette sous categorie avlors on va l'afficher
        if (data_visuel) {
            var elt = $('#' + data_visuel);
            // Si l'élement n'est pas déjà actif on reset et on l'active
            if (!elt.hasClass('current')) {
                $('.visu_menu').removeClass('current');
                elt.addClass('current');
            }
        }
    });

    function menuItemClose(submenu) {

        clearTimeout(menu_timeout);

        menu_timeout = setTimeout(function () {

            menuShade.removeClass('actif');
            $(".mainMenu").removeClass('actif');
        });
    }

    $(document).on("scroll", function () {

        menuItemClose($(".submenu_wrapper:visible"));
    });

    $('.mainMenu .menu_itm_hover').on('mouseleave', function () {

        menuItemClose($(".submenu_wrapper", this));
        $(this).removeClass("hovering bgSprite bfr logoBlue");
    });

    $('mainMenu .submenu_wrapper .close_menu').on('click touch', function () {
        $('#super_categ_1').removeClass('hovering');
    });


    /*fix_rayon_display();*/

    if ($("#form_recherche").length > 0) {
        $("#form_recherche").on('click touch', function (evt) {
            evt.stopPropagation();
            if ($("#form_recherche").hasClass("searching")) {
                if (!$(".wrap_search_field input").is(':focus')) {
                    $(".wrap_search_field").removeClass("searching");
                    $("#form_recherche").removeClass("searching");
                }
            } else {
                $(".wrap_search_field").addClass("searching");
                $("#form_recherche").addClass("searching");
            }
        });
    }

    if ($(".promo_swiper .swiper-slide").length > 0) {
        promoSwiper = new Swiper(".promo_swiper", {
            centeredSlides: true,
            loop: true,
            slidesPerView: 1,
            navigation: {
                nextEl: '.navpromo.promoNext',
                prevEl: '.navpromo.promoPrev',
            },
            speed: 1500,
            autoplay: {
                delay: 4500,
                disableOnInteraction: false,
            },
            autoplayDisableOnInteraction: false
        });
    }

    if ($(".bank_left_side").length > 0) {
        $( ".wrapper_bloc_banque" ).on( 'click touch', function ( evt ) {
            $( this ).addClass('actif');
            $(".wrapper_bloc_banque").not($(this)).removeClass('actif');
        });
    }

    // Chargement de la tarification
    if ($("#tarification_load").length > 0) {

        // Chargement des différentes tarification
        $.ajax({
            url: path_relative_root + 'ajax_tarification_list.php',
            type: 'post',
            dataType: 'json',
            success: function (res) {
                if (res !== []) {
                    var div = $('<div>', {
                        'class': 'custom-select custom-select-footer'
                    });
                    var select = $('<select id="country_tarif" name="country">');
                    $.each(res, function (key, val) {
                        var option = $("<option />", {
                            value: key,
                            text: val.pays_nom
                        });
                        if (val.selected) {
                            option.prop('selected', true);
                        }
                        $(option).appendTo(select);
                    });
                    // Event sur le select lorsque l'on change de pays
                    select.on('change', function () {
                        var country_iso = $(this).val();
                        // On va envoyé au serveur que l'on change de pays
                        $.ajax({
                            url: path_relative_root + 'ajax_tarification_change_country.php',
                            type: 'post',
                            data: 'iso=' + country_iso,
                            success: function () {
                                location.reload();
                            }
                        });
                    });
                    select.appendTo(div);
                    div.appendTo($("#tarification_load"));
                    initCustomSelect('custom-select-footer');
                } else {
                    $("#tarification_load").css('display', 'none');
                }
            }
        });
    }

    $("#openOrderGiftCard").click(function() {
        var cgvLink = "<div class='cgv_giftcard'><a href='"+ path_relative_root + create_link('order_cgv') +"'>" + Translator.translate('giftcard_cgv') + "</a><div>";

        // change title
        $("section.pageGiftCard__header div.headerTitles h2").text(Translator.translate('giftcard_offer_it'));
        // change css of title
        $("section.pageGiftCard__header").addClass("acheter_cart_cadeau");
        // hide first section
        $(".pageGiftCard__main--infos").addClass("is-hidden");
        // increase height on pagegiftcard
        $(".pageGiftCard").addClass("acheter_cart_cadeau");
        // add lightbox
        $("#lightboxOrderGiftCard").appendTo(".pageGiftCard__steps");
        // add cgv link
        $('#step3').after(cgvLink);
        // change maxlength on text area
        $("#s_msgPerso").attr('maxlength','120');

        $("input[type=radio][name=giftcardTemplate]").change(function() {
            if (this.checked) {
                // Get radio button's ID
                var Picture = $(this).data('attr-picture');

                // Set the image source in the aside tag on choosing radio button option
                $(".pageGiftCard__asideImg img").attr("src", "img/carte_cadeau/template/" + Picture);
            }
        });

        // Change gift card visual when lightbox is visible
        if ($("#lightboxOrderGiftCard").css("display") === "block") {
            // Set aside tag image based on the default selected radio button
            var defaultOption = $("input[type=radio][name=giftcardTemplate]:checked");

            if (defaultOption.length > 0) {
                var defaultPicture = defaultOption.data('attr-picture');

                 // Set the image source in the aside tag by default checked radio button option
                $(".pageGiftCard__asideImg img").attr("src", "img/carte_cadeau/template/" + defaultPicture);
            }
        }
    });

    $(".pageGiftCard__main--infos .giftCard_btn #showAmount").click(function() {
        if ($('.pageGiftCard__main--amountinfos').hasClass('logged')) {
            $(".pageGiftCard__main--amountinfos").removeClass("is-hidden");
            $(".pageGiftCard__main--amountinfos .showAmountBtn").removeClass("is-hidden");
        }
    });

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
            }, 200);
        }
    });

    initCustomSelect();
    $(".custom-select select").each(function () {
        var count = $(this).find("option").not(':empty').length;
        if (count == 1) {
            $('.custom-select .select-selected').addClass("removeAfter");
        }
    });

    // view 4
    if (readCookie("nb_produits") == 3) {
        if ($('#list_item').hasClass('fourRows'))
            $('#list_item').removeClass('fourRows');

        $('.display.viewDisplayfour').removeClass('actif');
        $('.display.viewDisplaythree').addClass('actif')

    } else {
        //Default view x4
        if (!$('#list_item').hasClass('fourRows'))
            $('#list_item').addClass('fourRows');

        $('.display.viewDisplaythree').removeClass('actif');
        $('.display.viewDisplayfour').addClass('actif');
    }

    /** Connexion formulaire end **/

    if ($('body').hasClass('footer_company')) {
        $(window).scroll(function (event) {
            var scroll = $(window).scrollTop();
            $('body.footer_company #site_head_wrap').toggleClass('changeColor',
                //add 'ok' class when div position match or exceeds else remove the 'ok' class.
                scroll > $('#changeMenuColor').offset().top + 125
            );
        });
        //trigger the scroll
        $(window).scroll(); //ensure if you're in current position when page is refreshed
    }

    //If search results <= 4, force display by 4
    $(window).scroll(function() {

        if($("#search_page").length == 1){
            var item = $("#list_item").find($(".item"));

            if(item.length <= 4){
                $(".viewChange").hide();
                $(".main_filter_wrapper").css("margin-left", "8%");
                $('#list_item').removeClass('fourRows')
                                .addClass('threeRows');
                createCookie("nb_produits", 3, 1);
            }
        }
    })

    resizeIframe();
    $(window).resize(function() {
        resizeIframe();
    });

    $('.closebtn.bgSprite.bfr.cross').on('click', function(){
        $('#locatShop').hide();
    });

    $('.submenu_wrapper .submenu.ss_menu_categ_wrapper .wrap_ul .objet').each(function() {
        if ($(this).children().length > 5) {
            $(this).css('min-width','18rem');
        }

        if ($(this).children().length > 8) {
            $(this).css('min-width','25rem');
        }
    });

    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });

    $("#shad, #close_numero_retour").on("click", function() {
        $("#shad").css('display', 'none');
        $("#popup_numero_retour").css('display', 'none');
    });

    $("#com_onglet").on("click", ".onglet", function (evt) {

        if ($("a", this).length && !$(this).hasClass("actif")) {

            $(this).addClass("actif").siblings().removeClass("actif");
        }
    });

    var cgvTableInfos = $(".cgvTableInfos");
    $(".cgvTableCountry").on("click", function () {

        $(this).toggleClass("opened");
        $(this).next(cgvTableInfos).toggleClass("opened");
        $(this).next(cgvTableInfos).css("width", "100%");
    });

    window.addEventListener("scroll", fix_elements);
    fix_elements();

    /* AUTO-TOGGLE ON CONTACT PAGE START */
    var get_param = getUrlParams('sq');

    if (get_param != '' && Object.prototype.toString.call(get_param) == '[object String]') {
        get_param = get_param.split(',');
        $('#service_' + get_param[0] + ' span.item_title').trigger("click");
        $('#question_' + get_param[0] + '_' + get_param[1] + ' span.question_title').trigger("click");
        setTimeout(function () {
            $('html, body').animate({
                scrollTop: ($('#question_' + get_param[0] + '_' + get_param[1]).offset().top - $('nav.mainMenu').height() - 30)
            }, 300);
        }, 300);
    }

    if ($('body').hasClass('in_tunnel') != true) {
        $(".wrapper_login.left .ancientCustomerLogin p").click(function () {
            showBlocForm('is_client', null);
        });

        $(".wrapper_login.right .ancientCustomerLogin p").click(function () {
            showBlocForm('is_not_client', null);
        });
    }

    // Select all links with hashes
    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
            location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top - 113
                }, 1000, function () {
                    // Callback after animation
                    // Must change focus!
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) { // Checking if the target was focused
                        return false;
                    } else {
                        $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                        $target.focus(); // Set focus again
                    };
                });
            }
        }
    });

    //Menu responsive
    $( '#trigger_menu_responsive' ).on( 'click touch', function () {
        if ( !$( this ).hasClass( 'open' ) ) {
            $( this ).addClass( 'open' );
            $( '#left_menu' ).addClass( 'open' );
            $( '#shad_menu_responsive' ).addClass( 'actif' );
            $( 'body' ).addClass( 'menu_left_open' );
        } else {
            $( this ).removeClass( 'open' );
            $( '#left_menu' ).removeClass( 'open' );
            $( '#shad_menu_responsive' ).removeClass( 'actif' );
            $( 'body' ).removeClass( 'menu_left_open' );
        }
    });

    $('#left_menu .main_menu_itm.menu_itm_hover .has_submenu a').click(function(e) {
        e.preventDefault();
        var this_submenu = $(this).parent('.main_menu_itm');

        if (!$(e.target).parents('span').hasClass('.has_submenu')) {
            e.preventDefault();
            if (!$(this).parents('.main_menu_itm').hasClass('important')) {
                $('.main_menu_itm .submenu_wrapper').slideUp();
                $('.main_menu_itm').removeClass('important');
                $(this).parents('.main_menu_itm').addClass('important');
                $(this).parents('.main_menu_itm').find( '.submenu_wrapper' ).slideDown();
                $(this).parents('.main_menu_itm').find( '.submenu_wrapper .wrap_ul .objet' ).lionbars();
            } else {
                //redirect if second click
                window.location = $(this).attr('href');
            }
        }
    });
});

$(function () {
    // Left alone in a separate $(function) to avoid issues with 'use strict'
    'use strict';
    $("#cart_list").lionbars();
    $("#cart_top").on('mouseenter touchstart', function (evt) {
        $("#cart_list").lionbars();
    });
});

function resizeIframe(){
    setTimeout(function () {
        var getSwiperHomeIframe = $("#super_home_swiper .slide_video iframe");
        getSwiperHomeIframe.removeAttr('width');
        getSwiperHomeIframe.removeAttr('height');
    }, 200);
}

function toggleGiftCardBalance() {
    var connectBtn = $(".btn_container");

    if (connectBtn.hasClass("notLogged")) {
        // redirects to idenfication when not logged
        location.href=path_relative_root+create_link('order_identification')+'?from=giftcard';
    } else {
        $(".pageGiftCard__main--amountinfos").toggleClass('is-hidden');
        $(".pageGiftCard__main--amountinfos .showAmountBtn").toggleClass("is-hidden");

        if ($("#shad").css("display") === "block") {
            // when return button clicked (pop-up)
            $('#shad').fadeTo("fast", 0, function () {
                $('#shad').css('display', 'none');
                $(".pageGiftCard__main--amountinfos").addClass("is-hidden");
            });
        } else {
            // add shad after idenfication
            $("#shad").css("display", "block").fadeTo("fast", 0.8).on("click", function () {
                closeGiftCardPopup();
            });
        }
    }
};

function closeGiftCardPopup() {
    // when clicking outside pop-up
    $('#shad').off('click');
    $('#shad').fadeTo("fast", 0, function () {
        $('#shad').css('display', 'none');
        $(".pageGiftCard__main--amountinfos").addClass("is-hidden");
    });
};

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
function topCartAppear() {
    var show_top_cart = $('#show_top_cart');
    if (!show_top_cart.hasClass("appear")) {
        $("#cart_list").lionbars();
        show_top_cart.addClass("appear");
        $('.connexion_top').removeClass("appear");
        $("#cart_top").addClass("underlined");
        $("a.cart .my_cart").addClass("bgSprite bfr logoBlue");
        $('#shad_menu').addClass("actif rollPanier");
    }
}

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
function topCartDesappear() {
    var show_top_cart = $('#show_top_cart');
    if (show_top_cart.hasClass("appear")) {
        show_top_cart.removeClass("appear");
        $("#cart_top").removeClass("underlined");
        $("a.cart .my_cart").removeClass("bgSprite bfr logoBlue");
        $('#shad_menu').removeClass("actif rollPanier");
        $(".block_top_item.search, .block_top_item.switchLang, .block_top_item.my_account .rolloverTxt, .block_top_item.my_basket .rolloverTxt").removeClass("fadeOutUp");
        $(".my_cart").html(Translator.translate('my_basket'));

    }
}

/**
 * Annule le fonctionnement ancien du filtre desktop
 */
if ($(".filtre_trigger").length > 0) {
    if ($(".triggered").length == 0) {
        $(".block_filters").css('display', 'flex');
    }
}

function btnLoaderTrigger(evt) {

    "use strict";

    var element = $(this);

    if (element.attr('id') == 'js-submitStep3') {
        var loader = $(this).next(".loader");
    } else {
        var loader = $(this).last(".loader");
    }

    if (loader.length > 0) {

        // Hipay submit was completely blocked on desktop without this. Be careful to make a payment test if you remove the condition.
        if (element.attr('id') != 'hipay-3-submit-button' && !document.getElementsByClassName('store_locator_page')) {
            evt.preventDefault();
        }

        loader.removeAttr("style").parent().addClass("loading");

        setTimeout(function () {
            loader.parent().removeClass("loading");
        }, 1200);

        if ($(this).attr("href")) {

            window.location.assign($(this).attr("href"));
        }

        // Dans le cas où il y a plusieurs bouton  en loading
        window.setTimeout(function () {
            $('.form_submit.loading').removeClass('loading')
        }, 5000);
    }
}

/**
 * New functions (they are supposed to be at least a little bit cleaner than legacy code)
 */
// Below lies the legacy code (enter at your own risk)
function checkdate(d, m, y) {

    "use strict";

    return m > 0 && m < 13 && y > 0 && y < 32768 && d > 0 && d <= (new Date(y, m, 0)).getDate();
}

var nav_UA = window.navigator.userAgent.split('/'),
    nav_ver = parseFloat(nav_UA.pop()),
    nav_name = nav_UA.pop();

var id_select = '';

// Définit les classes initiales pour les elements d'un formulaire
function setInitialClassName(elementIdArray) {

    "use strict";

    for (i = 0; i < elementIdArray.length; i++) {

        var elementInput = document.getElementById(elementIdArray[i]);
        var elementLabel = document.getElementById(elementIdArray[i] + 'Label');

        if (elementInput && elementInput.type != 'hidden') {

            elementInput.className = 'inputForm';
            if (elementLabel) {

                elementLabel.className = 'labelForm';
            }
        }
    }
}

// Définit les classes initiales pour les elements de date d'un formulaire
function setInitialDateClassName(elementIdArray) {

    "use strict";

    var elementInputDay, elementInputMonth, elementInputYear, elementLabel;

    for (i = 0; i < elementIdArray.length; i++) {

        elementInputDay = document.getElementById(elementIdArray[i] + 'J');
        elementInputMonth = document.getElementById(elementIdArray[i] + 'M');
        elementInputYear = document.getElementById(elementIdArray[i] + 'A');
        elementLabel = document.getElementById(elementIdArray[i] + 'Label');

        if (elementInputDay && elementInputDay.type != 'hidden' &&
            elementInputMonth && elementInputMonth.type != 'hidden' &&
            elementInputYear && elementInputYear.type != 'hidden') {

            elementInputDay.className = 'inputForm';
            elementInputMonth.className = 'inputForm';
            elementInputYear.className = 'inputForm';
            if (elementLabel) {

                elementLabel.className = 'labelForm';
            }
        }
    }
}

// Vérification d'un bouton radio
function verif_radio(elementID, fieldname) {

    "use strict";
    if (elementID == 'clientCiviliteM' || elementID == 'clientCiviliteMme' || elementID == 'clientCiviliteMlle') {

        elementID = 'clientCivilite';
    }

    var objElement = document.getElementsByName(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var trouve = false;
    var t_error = null;

    $(objElement).removeData('data-error-text').removeAttr('data-error-text');

    if (elementID) {

        $("input[name='" + elementID + "']").each(function (idx) {

            if ($(this).is(':checked') == true) {

                objElement.className = 'inputForm';
                trouve = true;
            }
        });

        if (!trouve) {

            if (elementID == 'clientCivilite') {

                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_civilite&arg2=' + fieldname);
            } else {

                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
            }

            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        } else {

            $(objElement).removeClass('inputErr');
            $(objLabel).removeClass('labelErr');
        }
    }
    return true;
}

function verif_price(e, t) {

    "use strict";

    var a = document.getElementById(e),
        r = document.getElementById(e + "Label"),
        l = (document.getElementById("mes_err_fieldset"), document.getElementById("erreur_msg"));

    if ($(a).removeData("data-error-text").removeAttr("data-error-text"), "" == a.value) return t_error = '<p class="title_toolltip">' + ajax_file("ajax_sprintf.php?arg1=" + t) + " :</p>" + ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t), $(a).data("data-error-text", t_error), l.innerHTML = t_error, a.className = "inputErr", r.className = "labelErr", !1;

    var n = validate_price(a.value);

    return 0 == n ? (t_error = '<p class="title_toolltip">' + ajax_file("ajax_sprintf.php?arg1=" + t) + " :</p>" + ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t), $(a).data("data-error-text", t_error), l.innerHTML = t_error, a.className = "inputErr", r.className = "labelErr", !1) : (a.className = "inputForm", r.className = "labelForm", !0)
}

function validate_price(e) {
    return /^[\d\.,]+$/.test(e)
}

/******* */

// Vérificartion d'une case coché
function verif_checkbox(elementName, fieldname) {
    var objElement = document.getElementsByName(elementName);
    var objLabel = document.getElementById(elementName.slice(0, -2) + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (elementName) {
        objElement.className = 'inputForm';
        objLabel.className = 'labelForm';
        for (i = 0; i < objElement.length; i++)
            if (objElement[i].checked == true) {
                objElement.className = 'inputForm';
                objLabel.className = 'labelForm';
                return true;
            }
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    return true;
}

function verif_checkbox_index(elementName, fieldname, pIndex) {
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (document.prodForm.visuStatutDetail1) {
        if (document.prodForm.visuStatutDetail1[0].checked == true) {
            return true;
        }
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_photo_validate');
        return false;
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_photo_validate');
        return false;
    }
}

function verif_zip(elementZipID, elementCountryID, fieldname, nb) {
    var objZipElement = document.getElementById(elementZipID);
    var objCountryElement = document.getElementById(elementCountryID);
    var objLabel = document.getElementById(elementZipID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');

    var idPays = objZipElement.value.substr(0, 2);

    if (objCountryElement.value == "DO" && idPays != '97') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=97');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if (objCountryElement.value == "MO" && idPays != '98') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=98');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if (objCountryElement.value == "TO" && idPays != '97' && idPays != '98') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=97ou98');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if ((objCountryElement.value != "DO" && objCountryElement.value != "TO" && objCountryElement.value != "MO") && (idPays == '97' || (idPays == '98' && objCountryElement.value != "FR"))) {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_2&arg2=' + fieldname + '&arg3=' + idPays);
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    }

    $(objZipElement).removeClass('inputForm');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_num(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;

    if (objElement.value == "") {
        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');

        return false;
    } else {
        if (objElement.value.length < nb) {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_x_digits&arg2=color:red;&arg3=' + fieldname + '&arg4=' + nb);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + fieldname);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (test == '.') {
                point = point + 1;
            }
            if (test == ',') {
                virgule = virgule + 1;
            }
        }
        var tot = point + virgule;
        if (point > 1 || virgule > 1 || tot > 1) {
            erreurText.innerHTML = erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + fieldname);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }

    }
    $(objElement).removeClass('inputErr');
    $(objElement).removeClass('labelErr');
    return true;
}

function verif_portable(elementID, fieldname) {
    return verif_telephone(elementID, fieldname, true);
}

function verif_telephone(elementID, fieldname, is_mobile) {

    var objElementHidden = document.getElementById(elementID + 'Hidden');
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById('erreur_msg');

    if (objElementHidden !== null) {

        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == '337') {

            if (elementID === 'clientTel') {

                $('#clientMobileHidden').val(value);
            } else if (elementID == 'telephone') {

                $('#mobileHidden').val(value);
            } else if (elementID == 'billtelephone') {

                $('#billmobileHidden').val(value);
            } else if (elementID == 'candidatTel') {

                $('#candidatTelHidden').val(value);
            }
        } else {
            if ($(objElement).intlTelInput("isValidNumber") !== false && (type === 0 || type === 2 || type === 3 || type === 6)) {

                $(objElementHidden).val(value);
            } else if ($(objElement).intlTelInput("isValidNumber") !== false && type === 1) {

                if (elementID === 'clientTel') {

                    $('#clientMobileHidden').val(value);
                } else if (elementID == 'telephone') {

                    $('#mobileHidden').val(value);
                } else if (elementID == 'billtelephone') {

                    $('#billmobileHidden').val(value);
                } else if (elementID == 'candidatTel') {

                    $('#candidatTelHidden').val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {

            $(objElement).addClass('inputErr');

            return false;
        }

        $(objElement).removeClass('inputErr');

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {

        if ($(objElement).intlTelInput("isValidNumber") === false || ((is_mobile && $(objElement).intlTelInput("getNumberType") === 0)) || (is_mobile == false && $(objElement).intlTelInput("getNumberType") === 1)) {

            if (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) {

                erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg2=' + fieldname + '_mobile') + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_mobile&arg2=' + fieldname);
            } else {

                erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg2=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_phone&arg2=' + fieldname);
            }

            return false;
        } else {

            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass('inputErr');

    return false;
}

function verif_ref_interne(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 9)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=9');
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 9) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=9&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length > 14) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_ref_14_chars&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_alphanum_codepromo(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\-_/\.<>()%:;";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 4)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=4');
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 4) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=4&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_empty(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        erreurText.innerHTML = t_error;
        $(objElement).data('data-error-text', t_error);
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        $(objElement).removeClass('inputForm');
        $(objLabel).removeClass('labelForm');
        return false;
    }
    $(objElement).addClass('inputForm');
    $(objLabel).addClass('labelForm');
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_alphanum(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum = decodeURIComponent("%C2%B0") + "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\'\"\-_/\.<>\n\r()%:; " + decodeURIComponent("%C3%A2%C3%A0%C3%A4%C3%A9%C3%A8%C3%AB%C3%AA%C3%AC%C3%AF%C3%AE%C3%B6%C3%B4%C3%B2%C3%B9%C3%BC%C3%BB%C3%A7&%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8A%C3%8C%C3%8F%C3%8E%C3%96%C3%94%C3%92%C3%99%C3%9C%C3%9B%C3%87");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        if (isMin == false && objElement.value.length < 3) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0) {
                flg++;
            }
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }
        if (isMin == true && objElement.value.length > length) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=' + fieldname + '&arg3=' + length);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_alphanumadresse(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\'\"_/\.<>\n\r()%:; " + decodeURIComponent("%C3%A2%C3%A0%C3%A9%C3%A8%C3%AB%C3%AAi%C3%AF%C3%AE%C3%B6%C3%B4%C3%B9%C3%BC%C3%BB%C3%A7%26%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8AI%C3%8F%C3%8E%C3%96%C3%94%C3%99%C3%9C%C3%9B%C3%87%3F!%E2%82%AC-");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        if (objLabel)
            objLabel.className = 'labelErr';
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            return false;
        }
        if (isMin == false && objElement.value.length < 3) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            flg = 0;
            return false;
        }
        if (isMin == true && objElement.value.length > length) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=' + fieldname + '&arg3=' + length);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    }
    objElement.className = 'inputForm';
    if (objLabel)
        objLabel.className = 'labelForm';
    return true;
}

function verif_textarea(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"\\n\r-_/.()%:; éééééiééééééé";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 3) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'textarea';
    objLabel.className = 'labelForm';
    return true;
}

function verif_textarea2(elementID, fieldname) {
    var flg = 0;
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        return false;
    }
    if (objElement.value.length < 3) {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'textarea';
    objLabel.className = 'labelForm';
    return true;
}

function verif_mail(elementID, fieldname, is_spe) {

    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var mail = new RegExp("^[a-z0-9_-]+([.]?[a-z0-9_-]{1,})*@([a-z0-9_-]{2,}[.])+[a-z]{2,4}$", "gi");

    path = '';
    if (typeof (path_relative_root) != 'undefined')
        path += path_relative_root;

    if (mail.exec(objElement.value) == null) {
        if (typeof (is_spe) == 'undefined') {
            var translations = ajax_file(path + 'ajax_sprintf.php?arg0=%s|%s' +
                '&arg1=' + fieldname +
                '&arg2=js_wrong_input_mail'
            );
            translations = translations.split("|");
            type_champ = translations[0];
            type_message = translations[1];

            erreurText.innerHTML = '<p class="title_toolltip">' + type_champ + ' :</p>' + type_message;
        }
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;

    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_alpha(elementID, fieldname) {
    var flg = 0;
    var alphanum = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"-_/.ééÈèËëÊêÔôÎîÛûÂâÏïÄÖöäÜüÀàÙùÇç&n " + decodeURIComponent("%C3%A0%C3%A9%C3%A8%C3%AB%C3%AAi%C3%AF%C3%AE%C3%B6%C3%B4%C3%B9%C3%BC%C3%BB%C3%A7%C3%A4%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8AI%C3%8F%C3%8E%C3%96%C3%94%C3%99%C3%9C%C3%9B%C3%87%C2%A0 ");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 2)) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=2');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        if (objElement.value.length < 2) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=2&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alpha&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }
    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_format_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value != '') {
        var regDateVente = new RegExp("^(\\d{2})/(\\d{2})/(\\d{2})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_1&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_2&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_date2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value != "") {
        var regDateVente = new RegExp("^(\\d{2})/(\\d{2})/(\\d{4})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_1&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_2&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_date_select(elementID, fieldname) {
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElementJ.value == "" || objElementM.value == "" || objElementA.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElementJ.className = 'inputErr';
        objElementM.className = 'inputErr';
        objElementA.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElementJ.className = 'inputForm';
    objElementM.className = 'inputForm';
    objElementA.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_majorite(elementID, fieldname) {
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    var date = new Date();
    var annee = date.getFullYear();
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElementA.value > (annee - 18)) {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_majorite');
        objElementJ.className = 'inputErr';
        objElementM.className = 'inputErr';
        objElementA.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElementJ.className = 'inputForm';
    objElementM.className = 'inputForm';
    objElementA.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_selected(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement) {
        var objLabel = document.getElementById(elementID + 'Label');
        if (objElement.value == "") {
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        } else {
            objElement.className = 'inputForm';
            objLabel.className = 'labelForm';
            return true;
        }
    }
    return true;
}

function verif_selected_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    if (objElementJ && objElementM && objElementA) {


        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        var error = false;
        if (objElementJ.value == "") {
            error = true;
            $(objElementJ).addClass('inputErr');
        } else {
            $(objElementJ).removeClass('inputErr');
        }
        if (objElementM.value == "") {
            error = true;
            $(objElementM).addClass('inputErr');
        } else {
            $(objElementM).removeClass('inputErr');
        }
        if (objElementA.value == "") {
            error = true;
            $(objElementA).addClass('inputErr');
        } else {
            $(objElementA).removeClass('inputErr');
        }
        if (!error) {
            $(objLabel).removeClass('labelErr');
            return true;
        } else {
            $(objLabel).addClass('labelErr');
            return false;
        }
    }
    return true;
}

function verif_selected2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement) {
        var objLabel = document.getElementById(elementID + 'Label');
        if (objElement.value == "") {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        } else {
            objElement.className = 'inputForm';
            objLabel.className = 'labelForm';
            return true;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
        return false;
    }
    return true;
}

function verif_categ(formID) {
    var objForm = document.getElementById(formID);
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objForm.prodCadeau) {
        if (objForm.prodCadeau.checked == false && !objForm.prodCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
        if (objForm.prodCadeau.checked == false &&
            objForm.prodCateg_0.selectedIndex &&
            !objForm.prodSousCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
        if (objForm.prodCadeau.checked == false &&
            objForm.prodCateg_0.selectedIndex &&
            objForm.prodSousCateg_0.selectedIndex &&
            !objForm.prodObjet_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
    } else {
        if (!objForm.prodCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_2');
            return false;
        }
        $errTMP = false;
        for (x = 0; x <= 4; x++) {
            if (eval("objForm.prodCateg_" + x + ".selectedIndex"))
                if (!eval('objForm.prodObjet_' + x))
                    $errTMP = true;
                else if (!eval('objForm.prodObjet_' + x + '.selectedIndex'))
                $errTMP = true;
        }
        if ($errTMP == true) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_3');
            return false;
        }
    }
    return true;
}

var indexImg = 0;

function nextImage(elementID, imgPath) {
    if (imgPath.length > 0) {
        indexImg++;
        if (indexImg >= imgPath.length)
            indexImg = 0;
        MM_swapImage(elementID, '', imgPath[indexImg], 1);
    }
}

function prevImage(elementID, imgPath) {
    if (imgPath.length > 0) {
        indexImg--;
        if (indexImg < 0)
            indexImg = imgPath.length - 1;
        MM_swapImage(elementID, '', imgPath[indexImg], 1);
    }
}

function NewWindow(mypage, myname, w, h, scroll) {
    LeftPosition = (screen.width) ? (screen.width - w) / 2 : 0;
    TopPosition = (screen.height) ? (screen.height - h) / 2 : 0;
    settings =
        'height=' + h + ',width=' + w + ',top=' + TopPosition + ',left=' + LeftPosition + ',scrollbars=' + scroll + ',resizable,';
    win = window.open(mypage, myname, settings);
}

function preg_replace(array_pattern, array_pattern_replace, my_string) {
    var new_string = String(my_string);
    for (i = 0; i < array_pattern.length; i++) {
        var reg_exp = RegExp(array_pattern[i], "gi");
        var val_to_replace = array_pattern_replace[i];
        new_string = new_string.replace(reg_exp, val_to_replace);
    }
    return new_string;
}

function verif_num_bandeau(elementID, fieldname) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num_bandeau&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_etage(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789rdcRDC.";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        if (objElement.value.length > nb) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=' + nb + '&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num_rdc&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verifSame(field1, field2, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (document.getElementById(field1).value != document.getElementById(field2).value) {
        var txt_lang = (fieldname == 'login_email_verif') ? 'js_same_email_confirm' : 'js_same_mdp_confirm';
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=' + txt_lang + '&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function isClosedDay(day, month) {
    var array_closed_array = new Array('1/1', '25/4', '1/5', '8/5', '2/6', '13/6', '14/7', '15/8', '1/11', '11/11', '25/12', '26/12');
    return array_closed_array.in_array(day + '/' + month);
}

(function () {

    'use strict';

    function in_array(p_val) {

        for (var i = 0, l = this.length; i < l; i++) {

            if (this[i] == p_val) {

                return true;
            }
        }

        return false;
    }

    Object.defineProperty(Array.prototype, 'in_array', {
        configurable: true,
        enumerable: false,
        writable: false,
        value: in_array
    });
}());

function changeVisu(field, path, evt) {

    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {

        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function (elm) {

            if (elm !== target) {

                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {

        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener('load', function () {

            this.alt = field.alt;

            this.setAttribute('width', this.naturalWidth);
            this.setAttribute('height', this.naturalHeight);

            if (field.parentElement) {

                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }
}

function getPos(obj) {
    var pos = {
        x: obj.offsetLeft || 0,
        y: obj.offsetTop || 0
    };
    while (obj = obj.offsetParent) {
        pos.x += obj.offsetLeft || 0;
        pos.y += obj.offsetTop || 0;
    }
    return pos;
}

function closeInfobulle(id) {
    if (!jQuery.browser.msie) $("#" + id).fadeOut(400);
    else document.getElementById(id).style.display = 'none';
    document.getElementById(id).style.zIndex = '0';
}

var one_time = false;

function openInfobulle(id, type, elem) {
    window.clearTimeout(timer);
    $("#bloc_erreur").css('display', 'none');
    if (!jQuery.browser.msie) $("#bloc_erreur").stop(false, true).fadeOut();
    if (!jQuery.browser.msie) $("#" + id).fadeIn(400);
    else document.getElementById(id).style.display = 'block';
    if (typeof (type) != 'undefined') {
        var margin_spe_left = 0;
        var margin_spe_top = 0;
        if (elem.nodeName == 'SELECT') {
            id = elem.id.substr(0, elem.id.length - 1);
            if (id == 'clientMailAuth' || id == 'clientPwdAuth' || id == 'mailLost') {
                var txt_spe = document.getElementById(id + 'ErrErr').innerHTML;
                margin_spe_left = 300;
                if (id == 'clientPwdAuth')
                    margin_spe_top = 10;
                infos_alias
            } else if (type == 'banque')
                var txt_spe = document.getElementById('infos_carte').innerHTML;
            else if (type == 'alias_banque')
                var txt_spe = document.getElementById('infos_alias').innerHTML;
            else
                var txt_spe = document.getElementById(id + 'Err').innerHTML;
        } else {
            if (elem.id == 'clientMailAuth' || elem.id == 'clientPwdAuth' || elem.id == 'mailLost') {
                if (document.getElementById(elem.id + 'ErrErr'))
                    var txt_spe = document.getElementById(elem.id + 'ErrErr').innerHTML;
                if (elem.id == 'mailLost')
                    margin_spe_left = -350;
                else
                    margin_spe_left = -340;
                if (elem.id == 'clientPwdAuth')
                    margin_spe_top = 41;
                else
                    margin_spe_top = 4;
            } else if (type == 'banque')
                var txt_spe = document.getElementById('infos_carte').innerHTML;
            else if (type == 'alias_banque')
                var txt_spe = document.getElementById('infos_alias').innerHTML;
            else
                var txt_spe = document.getElementById(elem.id + 'Err').innerHTML;
        }
        document.getElementById('erreur_msg').innerHTML = txt_spe;
        pos = getPos(elem);
        document.getElementById('bloc_erreur').style.position = 'absolute';
        if (type == 'banque') {
            margin_spe_left -= 42;
            margin_spe_top += 30;
        } else if (type == 'alias_banque') {
            margin_spe_left -= 42;
            margin_spe_top += 15;
        }
        document.getElementById('bloc_erreur').style.top = pos.y - (document.getElementById('bloc_erreur').offsetHeight + margin_spe_top - 2) + 'px';
        pos_left_margin = getPos(document.getElementById('content'));
        left_add = 0;
        if (document.getElementById('wrapper_anniversaire_jour1')) {
            left_add = document.getElementById('bloc_erreur').offsetWidth;
            left_add -= 35;
        }
        document.getElementById('bloc_erreur').style.left = (pos.x + (elem.offsetWidth - 8) + margin_spe_left + 27 - left_add) + 'px';
        if (type == 'banque' || type == 'alias_banque') {
            document.getElementById('bloc_erreur').style.width = '280px';
        } else {
            document.getElementById('bloc_erreur').style.width = '280px';
        }
        document.getElementById('bloc_erreur').style.zIndex = '10000';
    }
}

function OverPopup(elem, msg, type, is_vp) {
    clearTimeout(timer);

    if (type == 'visible') {
        if (typeof (is_vp) != 'undefined' && is_vp != 'cmpid_adwords') {
            $("#bloc_erreur_vp").css('display', 'none');
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp").stop(false, true).fadeOut();
            }
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp").fadeIn(400);
            } else {
                document.getElementById('bloc_erreur_vp').style.display = 'block';
            }
        } else {
            if (!document.getElementById('formAnniversaire') || (document.getElementById('formAnniversaire') && elementID != 'newsletterMailv' && elementID != 'newsletterMailInscrit')) {
                pos = getPos(elem);
                document.getElementById('erreur_msg').innerHTML = msg;
                if (!jQuery.browser.msie) {
                    $("#bloc_erreur").stop(true, true).fadeIn(400);
                } else {
                    document.getElementById('bloc_erreur').style.display = 'block';
                }
                document.getElementById('bloc_erreur').style.position = 'absolute';
                pos_left_margin = -13;
                var left_add = 17;
                if (document.getElementById('wrapper_anniversaire_jour1')) {
                    left_add = document.getElementById('bloc_erreur').offsetWidth;
                    left_add -= 35;
                }
                if (document.getElementById('formAnniversaire')) {
                    left_add = $('#erreur_msg').width() - 10;
                    $(".bottom_tooltip").attr('align', 'right');
                }
                document.getElementById('bloc_erreur').style.left = (pos.x + (elem.offsetWidth - 8) - pos_left_margin - left_add) + 1 + 'px';
                if (is_vp === 'cmpid_adwords') {
                    var clone = $('#bloc_erreur').clone(),
                        c_pos = {
                            top: $(elem).offset().top - $(elem).offsetParent().offset().top - $('#bloc_erreur').height() + 4,
                            left: $(elem).offset().left - $(elem).offsetParent().offset().left - $('#bloc_erreur').innerWidth() + ($(elem).width() * 2) + 6
                        };
                    $('#bloc_erreur').remove();
                    $(elem).after(clone);
                    $('#bloc_erreur').find('td:nth-child(2)').children('img').css({
                        'float': 'right',
                        'margin': '0 7px'
                    });
                    $('#bloc_erreur').css({
                        'position': 'absolute',
                        'top': c_pos.top,
                        'left': c_pos.left,
                        'z-index': '99999',
                        'opacity': '1'
                    });
                } else {
                    document.getElementById('bloc_erreur').style.top = (pos.y - document.getElementById('bloc_erreur').offsetHeight) + 'px';
                }
                if (elem.id == "departement_naissance") {
                    $('#bloc_erreur').css({
                        "width": 180,
                        "marginTop": 63,
                        "marginLeft": -310
                    });
                }
                document.getElementById('bloc_erreur').style.zIndex = '99999';
            }
        }
    } else {
        if (typeof (is_vp) != 'undefined' && is_vp != 'cmpid_adwords') {
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp").fadeOut(400);
            } else {
                document.getElementById('bloc_erreur_vp').style.display = 'none';
            }
            document.getElementById('bloc_erreur_vp').style.zIndex = '0';
        } else {
            if (!jQuery.browser.msie) {
                $("#bloc_erreur").stop(true, true).fadeOut(400);
            } else {
                document.getElementById('bloc_erreur').style.display = 'none';
            }
            document.getElementById('bloc_erreur').style.zIndex = '0';
        }
    }
}


function getXY(obj) {
    var curleft = 0;
    var curtop = obj.offsetHeight + 5;
    var border;
    if (obj.offsetParent) {
        do {
            if (getStyle(obj, 'position') == 'relative') {
                if (border = _pub.getStyle(obj, 'border-top-width')) curtop += parseInt(border);
                if (border = _pub.getStyle(obj, 'border-left-width')) curleft += parseInt(border);
            }
            curleft += obj.offsetLeft;
            curtop += obj.offsetTop;
        }
        while (obj = obj.offsetParent)
    } else if (obj.x) {
        curleft += obj.x;
        curtop += obj.y;
    }
    return {
        'x': curleft,
        'y': curtop
    };
}

/**
 * Returns the specified computed style on an object.
 * @param {HTMLObject} obj HTML Object
 * @param {String} styleProp Property name.
 * @return {Mixed} Computed style on object.
 */

/* pour les questions du panier panier */
function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#texte_question_" + i).css('display') == 'block') {

            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {

        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {

        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

/* pour les tableaux du guide des tailles */
function fadeTaille(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#content_quide_" + i).css('display') == 'block') {

            $("#content_quide_" + i).slideUp(400);
            $("#intitule_quide_" + i).removeClass('actif');
        }
    }

    if ($("#content_quide_" + id).css('display') != 'block') {

        $("#content_quide_" + id).slideDown(400);
        $("#content_quide_" + id).addClass('actif');
        $("#intitule_quide_" + id).addClass('actif');
    } else {

        $("#content_quide_" + id).slideUp(400);
        $("#content_quide_" + id).removeClass('actif');
        $("#intitule_quide_" + id).removeClass('actif');
    }
}

function fadeHelp(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#answer_" + i).css('display') == 'block') $("#answer_" + i).slideUp(400);
    }
    if ($("#answer_" + id).css('display') != 'block') $("#answer_" + id).slideDown(400);
    else $("#answer_" + id).slideUp(400);
}

function show_popup(elem) {
    openMultiShad(elem);
}

function close_popup(elem) {
    var $modbox = $("#" + elem);
    var $shad = $("#shad");
    $shad.attr('onclick', '');
    $shad.animate({
        opacity: 0
    }, 350, function () {
        $shad.css({
            display: 'none',
            opacity: ''
        }).data('opened_lb', 0);
    });
    $modbox.css({
        display: 'none'
    });
}

/********showblocform********/
function showBlocForm(id, type) {

    clearTimeout(timer);

    if ($('#bloc_erreur').css('display') == 'table' || $('#bloc_erreur').css('display') == 'block') {

        $('#bloc_erreur').css('display', 'none');
    }

    if (id == 'is_not_client') {

        $(".wrapper_login.left").addClass('actif');
        $(".wrapper_login.right").removeClass('actif');

        if ($("#bloc_lost_password").is(':visible')) {

            setTimeout(reloadBlocLogin, 100);
        }
    } else if (id == 'is_client') {

        $(".wrapper_login.left").removeClass('actif');
        $(".wrapper_login.right").addClass('actif');
    }
}

function afficheLostPasswordTop() {

    if ($('#bloc_erreur').is(':visible')) {

        $('#bloc_erreur').css('display', 'none');
    }

    $("#is_client_form").hide();
    $("#lost_password_top").css({
        display: "block",
        opacity: 0
    }).fadeIn(600);
}

function reloadBlocLoginTop() {

    if ($('#bloc_erreur').is(':visible')) {

        $('#bloc_erreur').css('display', 'none');
    }

    $("#lost_password_top").hide();
    $("#returning_customer_top").fadeIn(600);
}

function toggleFullScreen() {
    var video = document.getElementById("video_spa");
    if (video.requestFullScreen) {
        video.requestFullScreen();
    } else if (video.webkitRequestFullScreen) {
        video.webkitRequestFullScreen();
    } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
    }
}

function show_lightbox_3xcb() {
    $('#shad').fadeTo("slow", 0.8).queue(function () {
        $(this).css('display', 'block');
        $('#popup_3xcb').slideDown("slow");
        $(this).bind('click', close_lightbox_3xcb);
        $(this).dequeue();
    });
}

function close_lightbox_3xcb() {
    $('#popup_3xcb').hide();
    $('#shad').hide();
    $(this).unbind('click', close_lightbox_3xcb);
}

function in_array(needle, haystack, strict) {
    // http://kevin.vanzonneveld.net
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // *     example 1: in_array('van', ['Kevin', 'van', 'Zonneveld']);
    // *     returns 1: key
    var rKey = -1,
        strict = !!strict;
    if (haystack.length > 0) {
        for (key in haystack) {
            if ((strict && haystack[key] === needle) || (!strict && haystack[key] == needle)) {
                rKey = key;
                break;
            }
        }
    }
    return rKey;
}

function in_array_js(needle, haystack) {
    var rKey = -1;
    if (haystack.length > 0) {
        for (key in haystack) {
            if (haystack[key] == needle) {
                rKey = key;
                break;
            }
        }
    }
    return rKey > -1 ? true : false;
}

$.fn.recalImg = function (img) {
    var obj = $(this);
    var img;
    if (img == "" || img == null) {
        img = $("img", obj);
    } else {
        img = $(img, obj);
    }
    img.css({
        position: "",
        height: "",
        width: "",
        left: "",
        top: "",
        marginTop: "",
        marginLeft: ""
    });
    var widthObj = obj.outerWidth(true);
    var heightObj = obj.outerHeight(true);
    var widthImg = img.width();
    var heightImg = img.width();
    obj.css({
        position: "relative"
    });
    img.css({
        position: "absolute"
    });
    if ((heightImg > heightObj) || (widthImg > widthObj)) {
        if (widthImg > heightImg) {
            img.css({
                height: heightObj
            });
        } else if (widthImg <= heightImg) {
            img.css({
                width: widthObj
            });
        }
        var widthImgNow = img.outerWidth(true);
        var heightImgNow = img.outerHeight(true);
        var difHeight = (heightObj - heightImgNow) / 2;
        var difWidth = (widthObj - widthImgNow) / 2;
        if (widthImg > heightImg) {
            img.css({
                left: 0,
                top: 0
            });
        } else if (widthImg <= heightImg) {
            img.css({
                top: 0,
                left: 0
            });
        }
    } else {
        img.css({
            top: "0",
            left: "0"
        });
    }
    img.show();
};

function activeChange(p, c, s) {
    if ($(p).attr("value") == "") {
        $(c).css({
            color: "#aaa",
            fontStyle: "italic"
        });
    } else {
        $(c).css({
            color: "#000",
            fontStyle: "normal"
        });
    }
    c.text($('option:selected', s).text());
    if ($('option:selected', s).val() == "") {
        $(c).css({
            color: "#aaa",
            fontStyle: "italic"
        });
    }
}

$.fn.cbForm = function () {
    var t = $(this);
    var fs = $('.form_select', t);
    fs.css({
        opacity: "1"
    });
    fs.hover(function () {
        if (!$(this).hasClass("activ") && t.hasClass('active_bloc')) {
            $(this).css({
                opacity: "0.5"
            });
        }
    }, function () {
        if (!$(this).hasClass("activ") && t.hasClass('active_bloc')) {
            $(this).css({
                opacity: "1"
            });
        }
    });
    fs.click(function () {
        if (t.hasClass('active_bloc')) {
            fs.removeClass("activ");
            fs.css({
                opacity: "1"
            });
            $(this).addClass("activ");
            $(this).css({
                opacity: "0.5"
            });
            $(".type_carte option", t).removeAttr("selected");
            var typeCarte = $(this).attr("id");
            $("#type_carte_" + typeCarte, t).attr('selected', 'selected');
            p = $('.type_carte option:selected', t);
            c = $('.clone', $('.type_carte', t).parent('.selectStyled'));
            c.text(p.text());
            if ($(p).attr("value") == "") {
                $(c).css({
                    color: "#aaa",
                    fontStyle: "italic"
                });
            } else {
                $(c).css({
                    color: "#000",
                    fontStyle: "normal"
                });
            }
        }
    });
    $('.type_carte', t).change(function () {
        tcv = $('option:selected', this);
        var ctcv = tcv.val();
        fs.removeClass("activ");
        fs.css({
            opacity: "1"
        });
        if (ctcv != "") {
            $('.form_select#' + ctcv).addClass("activ");
            $('.form_select#' + ctcv).css({
                opacity: "1"
            });
        }
    });
}

function showrecuppanierlightbox() {
    var elname = 'recuppanier';
    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById(elname);
    var topconnexion = document.getElementById('topMenuConnexionButtonInside');
    var shad = document.getElementById('shad2');
    shad.style.display = "block";
    modbox.style.display = "block";
    if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('7.') != -1) {
        topconnexion.style.zIndex = -1;
    }
    addEventPromo(shad, 'click', closerecuppanier);
    window.scrollTo(0, actualposition);
}

function closerecuppanier() {
    var modbox = document.getElementById('recuppanier');
    var shad = document.getElementById('shad2');
    modbox.style.marginLeft = '';
    shad.style.display = "none";
    modbox.style.display = "none";

    delEventPromo(shad, 'click', closerecuppanier);
}

function addEventPromo(event, name_function) {
    if (document.all) document.getElementById('shad').attachEvent("on" + event, name_function);
    else document.getElementById('shad').addEventListener(event, name_function, false);
}

function delEventPromo(event, name_function) {
    if (document.all) document.getElementById('shad').detachEvent("on" + event, name_function);
    else document.getElementById('shad').removeEventListener(event, name_function, false);
}

function addFileInput(id_input, string) {

    var field = (typeof (string) != 'undefined') ? string : 'contactProduitPhoto';
    id = parseInt(id_input);
    var next_file = id + 1;
    var file = $('#' + field + id_input)[0].files[0];
    var error = false;
    var input = field == 'contactProduitPhoto' ? $('#photoProduit_display') : $('#photoProduitEndommage_display');
    var nb_input = field == 'contactProduitPhoto' ? $(input).val() : $(input).val();
    nb_input = parseInt(nb_input);
    size = file.size;
    type = file.type;
    var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf'); // Type de fichiers autorisés
    if (size > 4000000) {
        alert('Poids Max : 4Mo');
        error = true;
    } else if (jQuery.inArray(type, allowedType) == -1) {
        alert('Format autoris&eacute; : jpg, png, pdf');
        error = true;
    }
    if (error) {
        if (id_input == 1) {
            $('#field_send').addClass('cache');
            $('#' + field + next_file).remove();
        }
        $('#' + field + id_input).val('');
    } else {
        if (id_input == 1) {
            $('#field_send').removeClass('cache');
        }
        if (field != 'filepj') {
            var params = (typeof (string) != 'undefined') ? ',"' + string + '"' : '';
            var style = (typeof (string) != 'undefined' && string != 'contactProduitPhotoEndommage') ? 'style="float: left; padding-left: 32px;padding-top:-2px;"' : '';
            $('#' + field + id_input).after("<input " + style + " class='product_file' id='" + field + next_file + "' value='' type='file' name='" + field + next_file + "' onchange='addFileInput(" + next_file + params + ")' /> ");
            $(input).attr('value', next_file);
        } else {
            $('#' + field + id_input).parent().find(".file_msg p").text(file.name);
        }
    }
}

function uploadfile() { //fonction pour valider le fichier en pj dans le formulaire message
    var error = false;
    var fname = $('#message_file').val();
    var fsize = $('#message_file')[0].files[0].size; //get file size
    var ftype = $('#message_file')[0].files[0].type; // get file type
    var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf'); // Type de fichiers autorisés
    if (fsize > 4000000) {
        alert('Poids Max : 4Mo');
        error = true;
    } else if (jQuery.inArray(ftype, allowedType) == -1) {
        alert('Format autorise : jpg, png, pdf');
        error = true;
    }
    if (error) {
        $('#message_file').val('');
    } else {

    }
}

function openMultiShad(id) {
    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {

        $("#" + id).slideDown(600);
    });

    if (id !== 'zoomBox' && id !== 'lightboxOrderGiftCard') {
        $("#shad").css("display", "block").fadeTo("slow", 0.8).on("click", function () {
            closeMultiShad(id);
        });
    }

    if (id === "zoomBox") {

        $('#zoom_box_wrapper .swiper-slide').mousemove(function (event) {

            var slideOffset = $(this).offset();
            var relY = -1 * (event.pageY - slideOffset.top);

            $(this).find('img').css('top', relY + 100 + "px");
        });

        $('#zoom_box_wrapper .swiper-slide').on('click', function () {

            closeMultiShad('zoomBox');
        });
    }

    if ($("#buyNowSwiper").length > 0) {

        window.setTimeout(function () {

            buyNowSwiper = new Swiper("#buyNowSwiper", {
                sliderPerView: 1,
                navigation: {
                    nextEl: '.modboxNext',
                    prevEl: '.modboxPrev',
                },
            });
        }, 50);
    }

    if (id === "sizeguideBox") {
        $('#wrap_itable').overlayScrollbars({});
    }

    if ($('body.lookbook').length) {
        $('body').addClass('lightbox_freeze');
    }

    if (id === "lightbox_achat_express_v2") {

        window.setTimeout(function () {

            lazyload.init();
        }, 200);
    }
}

function closeMultiShad(id) {

    $('#shad').off('click');

    // Hide modbox and shade and destroy shade's click event
    $('#' + id).fadeOut("slow").queue(function () {

        if ($('#shad').is(":visible")) {
            $('#shad').fadeTo("slow", 0, function () {

                $('#shad').css('display', 'none');
            });
        }

        if ($('#shad_abox').is(":visible")) {
            $('#shad_abox').fadeTo("slow", 0, function () {

                $('#shad_abox').css('display', 'none');
            });
        }

        if ($('body.lookbook').length) {
            $('body').removeClass('lightbox_freeze');
        }

        $(this).dequeue();
    });

    if (id == "sendwishlistbox") {
        $("#sendwishlistbox").hide();
        $('#shad').css('opacity', '0');
    }

    if ($("#site_head_wrap").hasClass("modal-active")) {
        $("#site_head_wrap").removeClass("modal-active");
    }
}

function deplieMsg(id) {
    $('.btn-deplier').removeClass('deplier-opened');
    $('.detail_message').slideUp();
    $('#conversation_msg_' + id).slideDown().parent().find('.btn-deplier').addClass('deplier-opened');
}

function showDetailOrder(order_id, order_type) {
    if (show_orders_by_type('orders_by_type', order_type, ROOT_PATH))
        $('#order_' + order_id).click();
    $('html, body').animate({
        scrollTop: ($('[data-btn-name="order_section"]').offset().top - 100)
    }, 500);
}

function changeLocale(locale, uri, site) {

    $.ajax({
        url: path_relative_root + 'ajax_get_uri_by_lang.php',
        type: 'post',
        data: {
            lang: locale,
            url: uri,
            site: site
        },
        success: function (data) {
            if (data != '') {

                var force = '';
                if (uri == '/kujten.com') {
                    force = '?force';
                }

                location.href = data + force;

            }
        }
    });

}

function actionKeyCacheCache() {
    $('.key_cache_cache').bind('click', function () {
        var $objId = $(this).attr('id');
        openPopupCacheCache($objId);
    });
}

$.fn.new_styling_form = function (option) {
    var $obj = $(this);
    var param = $.extend({
        target_type: 'multi',
        type_class_sufix: 'styled',
        localName_new_elem: 'div',
        new_elem_id: true,
        height: 'auto',
        use_class: 'false',
        select_noValue_color: '#aaa'
    }, option);

    function animeElem(action, styled, height) {
        var useClass = param.use_class == true;
        styled.removeClass('unselected selected btndown');
        if (action == 'inActive') {
            styled.css({
                backgroundPosition: '0 0'
            }).addClass('unselected')
        }
        if (action == 'active') {
            styled.css({
                backgroundPosition: '0 -' + (height * 2) + 'px'
            }).addClass('selected');
        }
        if (action == 'btnDownToActive' || action == 'btnDownToInActive') {
            styled.addClass('btndown');
            if (action == 'btnDownToActive') {
                styled.css({
                    backgroundPosition: '0 -' + (height * 1) + 'px'
                })
            }
            if (action == 'btnDownToInActive') {
                styled.css({
                    backgroundPosition: '0 -' + (height * 3) + 'px'
                })
            }
        }
    }

    $obj.each(function () {
        var $objEach = $(this);
        var eachLocalName = $objEach[0].tagName.toLowerCase();
        var localNameValid = new Array('input', 'select');
        var flag = true;
        var eachType = $objEach[0].type;
        var isInputForm = $.inArray(eachLocalName, localNameValid) >= 0

        var acceptedType = new Array('radio', 'checkbox');
        var isAccepted = $.inArray(eachType, acceptedType) >= 0;
        var idElem = $objEach.prop('id');


        if (!isInputForm || (isInputForm == 'input' && !isAccepted)) {
            flag = false;
        }

        var elemType = param.target_type;
        if (elemType = 'multi') {
            elemType = eachType;
        } else if (elemType != eachType) {
            flag = false;
        }
        if ($objEach.data('isStyled')) {
            flag = false;
        }

        if (flag) {
            var prefix = (eachLocalName == 'input') ? eachType : eachLocalName;
            var styledClass = prefix + '_' + param.type_class_sufix
            if (param.type_class_sufix == '') {
                styledClass = prefix;
            }

            if (!$objEach.prev().is(param.localName_new_elem + '.' + styledClass)) {
                var $styledElem = $(document.createElement(param.localName_new_elem));
                $objEach.before($styledElem);
                $styledElem.addClass(styledClass).css({
                    cursor: 'pointer'
                });
                if (eachLocalName == 'input') {
                    $objEach.css({
                        display: 'none'
                    });
                }
                if (eachLocalName == 'select') {
                    $objEach.css({
                        position: 'relative',
                        opacity: 0,
                        zIndex: 5
                    });
                }
            } else {
                var $styledElem = $(param.localName_new_elem + '.' + styledClass);
            }

            if (param.new_elem_id) {
                $styledElem.prop('id', eachLocalName + $objEach.prop('id'))
            }

            var objHeight = param.height;
            if (objHeight == 'auto') {
                objHeight = $styledElem.height();
            }
            $objEach.data('isStyled', true);
            var objChecked = $objEach.is(':checked');

            if (eachLocalName == 'input') {
                if (objChecked) {
                    animeElem('active', $styledElem, objHeight);
                } else {
                    animeElem('inActive', $styledElem, objHeight);
                }
            }

            if (eachLocalName == 'input') {
                if (eachType == 'checkbox') {
                    $styledElem.on({
                        'mousedown': function (e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (objChecked) {
                                    animeElem('btnDownToInActive', $styledElem, objHeight);
                                } else {
                                    animeElem('btnDownToActive', $styledElem, objHeight);
                                }
                            }
                        },
                        'mouseup': function (e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (objChecked) {
                                    $objEach.prop('checked', null);
                                } else {
                                    $objEach.prop('checked', 'checked');
                                }
                                $objEach.trigger('change');
                            }
                        }
                    });
                    $objEach.on({
                        'change': function () {
                            var objChecked = $objEach.is(':checked');

                            if (objChecked) {
                                animeElem('active', $styledElem, objHeight);
                            } else {
                                animeElem('inActive', $styledElem, objHeight);
                            }
                        }
                    });

                }
                if (eachType == 'radio') {
                    var eachName = $objEach[0].name;
                    var $otherRadio = $obj.not($objEach).filter('[type=radio][name=' + eachName + ']');

                    $styledElem.on({
                        'mousedown': function (e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (!objChecked) {
                                    animeElem('btnDownToActive', $styledElem, objHeight);
                                }
                            }
                        },
                        'mouseup': function (e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (!objChecked) {
                                    $otherRadio.prop('checked', null);
                                    $objEach.prop('checked', 'checked');
                                    animeElem('active', $styledElem, objHeight);
                                    $otherRadio.each(function () {
                                        var otherEach = $(this);
                                        animeElem('inActive', otherEach.prev('.' + styledClass), objHeight);
                                    })
                                }
                                $objEach.trigger('change');
                            }
                        }
                    });
                    $objEach.on({
                        'change': function () {
                            var objChecked = $objEach.is(':checked');
                            if (objChecked) {
                                animeElem('active', $styledElem, objHeight);
                                $otherRadio.each(function () {
                                    var otherEach = $(this);
                                    animeElem('inActive', otherEach.prev('.' + styledClass), objHeight);
                                });
                            }
                        }
                    });
                }
            }
            if (eachLocalName == 'select') {
                if ($styledElem.text() == '') {
                    $styledElem.text($('option:selected', $objEach).text());
                }
                if ($('option:selected', $objEach).val() == '' || $('option:selected', $objEach).val() == undefined) {
                    $styledElem.css({
                        color: param.select_noValue_color,
                        fontStyle: 'italic'
                    });
                } else {
                    $styledElem.css({
                        color: '',
                        fontStyle: ''
                    });
                }
                $objEach.on({
                    'change': function () {
                        if ($('option:selected', $objEach).val() == '' || $('option:selected', $objEach).val() == undefined) {
                            $styledElem.css({
                                color: param.select_noValue_color,
                                fontStyle: 'italic'
                            });
                        } else {
                            $styledElem.css({
                                color: '',
                                fontStyle: ''
                            });
                        }
                        $styledElem.text($('option:selected', $objEach).text());
                    }
                })
            }
        }

    });
};

$.fn.selectChangeAdr = function () {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr('id');
    var data = {};
    var $changeContainer = $('.wrapper_form_adresse', $obj.closest('.adresse_change'));
    data.act = 'ajout';
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = 'ajax';

    if (obj_val != '') {

        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = 'modif';
        }
        if ($changeContainer.is('.show')) {
            $changeContainer.removeClass('show').slideUp().html('');
        }
        $.ajax({
            type: 'get',
            data: data,
            url: path_relative_root + 'livraison-ajax.php',
            success: function (response) {
                var $responce = $(response);
                var min_form = $('.min_form', $responce).html();
                var $form = $('#adresseForm', $responce);
                var all_form;

                $changeContainer.html($form.html(min_form)).css({
                    display: 'none'
                }).slideDown();
                $('.styled').new_styling_form({
                    type_class_sufix: '',
                    localName_new_elem: 'span'
                });
                if (!$changeContainer.is('.show')) {
                    $changeContainer.addClass('show').slideDown()
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.log(e);
                }
                initialize_google_place('delivery');
                $('.type_adresse', '.choix_type_adresse.delivery').on('click', function () {
                    $(this).show_adresse_title($('.type_adresse', '.choix_type_adresse.delivery'), '#adresseTitre');
                });

                var country_select = '';

                if (typeof $("#pays").val() != 'undefined') {

                    country_select = $("#pays").find(":selected").data('role').toLowerCase();

                    $("#pays").change(function () {

                        if (typeof $(this).find(":selected").data('role') != 'undefined') {

                            country_select = $(this).find(":selected").data('role').toLowerCase();

                        }

                        if (typeof $("#telephone").val() != 'undefined') {

                            $("#telephone").intlTelInput("setCountry", country_select);

                        }

                        if (typeof $("#mobile").val() != 'undefined') {

                            $("#mobile").intlTelInput("setCountry", country_select);

                        }

                    });

                } else {

                    country_select = "fr";

                }
            }
        });

    }
};

var inAnime = false;
$.fn.advanceSlide = function (to, callback) {

    var $obj = $(this);
    var isVisible = $obj.is(':visible');
    var callbackfn = function () {
        if (callback && typeof callback === 'function') {
            return callback();
        }
        return false;
    }
    if ($obj.data('height') == undefined || $obj.data('height') == 0) {
        if (!isVisible) {
            $obj.css({
                display: 'block',
                opacity: 0,
                position: 'absolute'
            });
        }
        $obj.data('height', $obj.height());
        if (!isVisible) {
            $obj.css({
                display: 'none',
                opacity: '',
                position: ''
            });
        }
    }

    var objH = $obj.data('height');
    if (inAnime == false) {
        inAnime = true;
        if (to == 'show' && !isVisible) {
            $obj.stop().css({
                display: 'block',
                overflow: 'hidden',
                height: 0
            }).animate({
                height: objH
            }, function () {
                $obj.css({
                    overflow: '',
                    height: ''
                });
                inAnime = false;
                callbackfn();
            });
        } else if (to == 'hide' && isVisible) {
            $obj.stop().css({
                display: 'block',
                overflow: 'hidden',
                height: objH
            }).animate({
                height: 0
            }, function () {
                $obj.css({
                    display: 'none',
                    overflow: '',
                    height: ''
                });
                inAnime = false;
                callbackfn();
            });
        } else {
            inAnime = false;
        }
    }
};

function billDisplay(that) {

    var $obj = $('#wrapper_bill');
    var $this = $(that);

    if (!$this.is(':checked')) {

        $obj.stop().slideDown();
    } else {

        $obj.stop().slideUp();
    }
}

/**
 * Enables hover state on any element when using a touch based device
 */

(function () {

    function nevermind() {

        return;
    }

    document.body.addEventListener("click", nevermind);
}());

function fix_elements() {
    fix_filter("#site_head_wrap", true, 71);

    if ($('#site_head_wrap').hasClass('promo_on')) {
        fix_filter('#fix_menu_compte', '#site_head_wrap', true, 159);
    } else {
        fix_filter('#fix_menu_compte', '#site_head_wrap', true, 108);
    }
}

function fix_filter(s_element, s_offset, recalc, h) {
    if ($(s_element).length) {
        if (recalc) {
            $(s_element).stick_in_parent({
                inner_scrolling: false,
                offset_top: h,
                recalc_every: 1
            });
        } else {
            $(s_element).stick_in_parent({
                inner_scrolling: false,
                offset_top: h
            });
        }
    }
}

/**
 * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
function setAvailableSizes() {

    if (window.json_colisages !== undefined) {

        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;

        for (size_id in sizes_tab) {

            if (sizes_tab.hasOwnProperty(size_id)) {

                size_box = $('[name="itm_size"]').filter('[value="' + size_id + '"]');
                is_disabled = !sizes_tab[size_id];

                if (!is_disabled) {
                    size_box.removeClass('disabled');
                } else {
                    size_box.addClass('disabled');
                }

            }
        }
    }
}


function createCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

function getUrlParams(prop) {
    var params = {};
    var search = decodeURIComponent(window.location.href.slice(window.location.href.indexOf('?') + 1));
    var definitions = search.split('&');

    definitions.forEach(function (val, key) {
        var parts = val.split('=', 2);
        params[parts[0]] = parts[1];
    });

    return (prop && prop in params) ? params[prop] : params;
}

/* AUTO-TOGGLE SCROLL ON CONTACT PAGE END */

/** Connexion formulaire start **/

$.fn.initAssocBloc = function () {

    var element = $(this);

    function displayItem(element) {
        var list = [];

        $("a", element).each(function (index) {
            list.push($(this));
        });

        list = shuffle(list);

        var length = list.length;
        if (length > 4) {
            length = 4;
        }
        element.html('');
        for (var i = 0; i < length; i++) {
            element.append(list[i]);
        }
    }

    /**
     * Shuffles array in place.
     * @param {Array} a items An array containing the items.
     */
    function shuffle(a) {
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    }

    displayItem(element);

};

/*********************************
Custom Select Menu
*********************************/

function initCustomSelect(select) {
    var x, i, j, selElmnt, a, b, c;
    if (select === undefined) {
        select = 'custom-select';
    }
    x = document.getElementsByClassName(select);
    // Si jamais l'element est déja customisé alors on n'y touche pas
    if ($('.' + select + ' .select-items').length > 0) {
        return;
    }
    for (i = 0; i < x.length; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        for (j = 0; j < selElmnt.length; j++) {
            // Si la valur est vide alors on ne la prend pas en compte
            if (selElmnt.options[j].value === '') {
                continue;
            }
            c = document.createElement("DIV");
            c.innerHTML = selElmnt.options[j].innerHTML;
            if (selElmnt.options[j].selected) {
                c.setAttribute("class", "same-as-selected");
            }
            c.addEventListener("click", function (e) {
                var y, i, k, s, h, l;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                h = this.parentNode.previousSibling;
                l = s.length;
                for (i = 0; i < l; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        // Trigger pour lancer l'evènement
                        $(s).trigger('change');
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");
                        for (k = 0; k < y.length; k++) {
                            y[k].removeAttribute("class");
                        }
                        // Pour signaler que c'est cet élément qui doit être sélectionné
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                }
            });
            b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function (e) {
            e.stopPropagation();
            // Raz de tous les autres select
            closeAllSelect(this);
            // On cache/affiche la liste
            this.nextSibling.classList.toggle("select-hide");
            // On change la direction de la fleche
            this.classList.toggle("select-arrow-active");
        });
    }
}

function updateCustomSelect(select_str) {

    if (select_str === undefined) {
        select_str = '.custom-select';
    }
    var select = $(select_str);

    // Evenement sur le select pour bien le gérer
    $('.select-selected', select).on("click", function (e) {
        e.stopPropagation();
        // Raz de tous les autres select
        closeAllSelect(this);
        // On cache/affiche la liste
        this.nextSibling.classList.toggle("select-hide");
        // On change la direction de la fleche
        this.classList.toggle("select-arrow-active");
    });
    // Maintenant pour chacun de ses fils on va mettre un evenement
    $('.select-items div', select).on('click', function () {
        var y, i, k, s, h, l;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        h = this.parentNode.previousSibling;
        l = s.length;
        for (i = 0; i < l; i++) {
            // En parcourant le select on va conparer le innerhtml du select avec le personalisé pour selectionner le bon element
            if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                // Trigger pour lancer l'evènement
                $(s).trigger('change');
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");
                for (k = 0; k < y.length; k++) {
                    y[k].removeAttribute("class");
                }
                // Pour signaler que c'est cet élément qui doit être sélectionné
                this.setAttribute("class", "same-as-selected");
                break;
            }
        }
    });
}

function closeAllSelect(elmnt) {
    var x, y, i, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i)
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }
    for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
        }
    }
}

document.addEventListener("click", closeAllSelect);

$("#search_home").on('input', function () {
    var value = $("#search_home").val();

    if (value.length >= 3) {
        $('body').addClass('search_in');
    } else {
        $('body').removeClass('search_in');
    }
});

function toggleDisplay() {

    $('.display').toggleClass('actif');

    if ($('.display.viewDisplayfour').hasClass('actif')) {
        $('#list_item').addClass('fourRows');
        $('#list_item').removeClass('threeRows');
        createCookie("nb_produits", 4, 1);
    } else {
        $('#list_item').removeClass('fourRows');
        $('#list_item').addClass('threeRows');
        createCookie("nb_produits", 3, 1);
    }
    setTimeout(function () {
        $('.productSwiper').each(function () {
            this.swiper.update();
        });
    }, 300);
}


if ($("#trigger_recherche").length > 0) {
    $("#trigger_recherche").on('click touch', function (evt) {
        if ($("#trigger_recherche").hasClass("searching") == false) {
            $(".wrap_search_field").fadeIn(300);
            $(".wrap_search_field").addClass("searching");
            $("body").addClass("body_fixed");
        }
    });
}

if ($(".wrap_search_field .close_it").length > 0) {
    $(".close_it").on('click touch', function (evt) {
        $("#search_home").val("");
        $(".wrap_search_field").fadeOut(300);
        $(".wrap_search_field").removeClass("searching");
        $("body").removeClass("body_fixed");
        $("body").removeClass("search_result");
        window.setTimeout(function () {
            $("#trigger_recherche").show();
        }, 300);
    });
}

// Search input behavior on keypress submit
document.getElementById('search_home').addEventListener('keypress', function(e) {
    if (e.key && (e.key == 'Enter')) {
        // If user hits the Enter button, closeSearch
        $(".wrap_search_field").fadeOut(300);
        $("body").removeClass("body_fixed");
        $("body").removeClass("search_result");
    } else {
        // Else, return
        return;
    }
})

$(".enterSub").on('click', function () {
    $('.menutest .subtest').toggleClass('cache');
});

$(".wrap_ul span").on('click', function () {

    $('.wrap_ul').removeClass('actif');

    var subObject = $(this).data('id');
    var subId = $(this).parent('.wrap_ul').data('id');

    if (subObject === subId) {
        $(this).parent('.wrap_ul').toggleClass('actif');
    }

});


$(".super_categ_menu .cat_name").on('click', function () {

    var subMenu = $(this).parent().children('.ss_menu_categ_wrapper');
    var catId = $(this).data('id');
    var superCatId = $(this).parents('.super_categ_menu').attr('id');

    $("#" + superCatId + "  .cat_name").removeClass('actif');
    $(this).addClass('actif');

    $('#' + superCatId + '  .ss_menu_categ_wrapper').addClass('cache');
    if (subMenu.length) {
        subMenu.toggleClass('cache');
    }

    $("#" + superCatId + " .menu_visuel_wrapper ").removeClass('cache');
    $("#" + superCatId + " .visu_menu").hide();
    $("#super_categ_1").removeClass('visu_on');

    if ($('#' + superCatId + ' .visu_menu[data-id=' + catId + ']').length) {
        $('#' + superCatId + '  .visu_menu[data-id=' + catId + ']').show();
        $("#super_categ_1").addClass('visu_on');
    }

    $("#super_categ_1 .submenu .ss_menu_categ_wrapper").lionbars();

});

$("#menu_maison .cat_name").on('click', function () {

    var subMenu = $(this).parent().children('.ss_menu_categ_wrapper');
    var rel = $(this).data('rel');

    $("#menu_maison  .cat_name").removeClass('actif');
    $(this).addClass('actif');

    $('#menu_maison  .ss_menu_categ_wrapper').addClass('cache');
    if (subMenu.length) {
        subMenu.toggleClass('cache');
    }

    $("#menu_maison .menu_visuel_wrapper ").removeClass('cache');
    $("#menu_maison .visu_menu").hide();

    if ($('#menu_maison .visu_menu[data-rel=' + rel + ']').length) {
        $('#menu_maison  .visu_menu[data-rel=' + rel + ']').show();
    }

});

function goBack(toUrl) {
    if (toUrl) {
        window.location.href = path_relative_root;
    } else {
        var url = window.location.href;
        if (url.indexOf("#search") > -1) {
            var new_url = url.substring(0, url.indexOf("#search"));
            window.location.href = new_url;
        } else if (url.indexOf("conseil") > -1) {
            if ($(".conseil_home").hasClass('actif')) {
                window.history.back();
            } else {
                $(".item_conseil").removeClass("actif");
                $(".conseil_home").addClass("actif");
            }
        } else {
            window.history.back();
        }
    }
}


// images appear on hover page history
$(".history_hover_span").on('mouseover', function () {
    var link = $(this).data("link");
    $('#' + link).addClass("active");
});

$(".history_hover_span").on('mouseleave', function () {
    $(".img_hover").removeClass("active");
});

$('.theme').on('click', function () {
    $('.theme').removeClass('actif');
    $(this).addClass('actif');
});
// change view page savoir faire
$('.satellite_nav_bar li').click(function () {
    var tab = $(this).data("tab")
    $(".tabsatellite").removeClass("active");
    $('.satellite_tab').removeClass('actif')
    $('#' + tab).addClass("actif")
    $(this).addClass("active");
})

$('body').on('click', '.wrap_rayon_info', function () {
    $('#trigger_recherche').click();
    $('#search_home').focus();
});


// Product image moving on mouse hover
$('.prodSwiperBig .swiper-slide').mousemove(function (event) {

    var posImg = event.target.getBoundingClientRect();

    // Then we measure how far into the image the mouse is in both x and y directions
    var mouseX = event.clientX - posImg.left;
    var mouseY = event.clientY - posImg.top;

    // Then work out how far through the image as a percentage the mouse is
    var xPercent = (mouseX / posImg.width) * 100;
    var yPercent = (mouseY / posImg.height) * 100;

    // Then we change the `transform-origin` css property on the image to center the zoom effect on the mouse position
    $(this).find('img').css('transform-origin', (xPercent + '% ' + yPercent + '%'));
})


// Menu
setTimeout(function () {
    $("#super_categ_1 .submenu .ss_menu_categ_wrapper").lionbars();
}, 300);

// Page presse
setTimeout(function () {
    $("#cms_page_title>h1.title").remove();
}, 300);

/**
 * Lightbox preco
 */
$(".bloc_lightbox_preco").on("click", function() {

    if (!$(this).hasClass("actif")) {

        $(".bloc_lightbox_preco.actif").removeClass("actif");
        $(this).addClass("actif");
    }
});

function disableStepZero() {
    if ($('#step3').css('display') === 'block') {
        $('.giftcardTemplate').prop('disabled', true);
    } else {
        $('.giftcardTemplate').prop('disabled', false);
    }
}


function initializeFloatingLabels() {
    if (document.body.classList.contains('giftcard_index')) {
        document.querySelectorAll('.formPerso .w-input').forEach(input => {
            const label = input.nextElementSibling;

            const handleInput = () => {
                if (input.value !== '') {
                label.style.transform = 'translateY(6px) scale(0.8)';
                label.style.opacity = '1';
                } else {
                label.style.transform = 'translateY(24px)';
                label.style.opacity = '0';
                }
            };

            input.addEventListener('input', handleInput);

            handleInput();
        });
    }
}

document.addEventListener('DOMContentLoaded', initializeFloatingLabels);

// LOOKBOOK POSITION PLACEMENT
var element1 = $('.lookbook:nth-child(31)');
var element2 = $('.lookbook:nth-child(30)');
element1.insertBefore(element2);

// change size in cart
$('body').on('click','.toggle_modal_actions',function() {
    $(this).fadeOut('fast');
    $(this).parents('.product_line_basket').find('.cart_item_actions').slideDown('slows');
});

$('body').on('click','.cart_item_actions_close',function() {
    $(this).parents('.product_line_basket').find('.cart_item_actions').slideUp('fast');
    $(this).parents('.product_line_basket').find('.toggle_modal_actions').fadeIn('slow');
});

function basketChangeSize(form) {

    let basketId = form.elements['panierId'].value;
    let productId = form.elements['product_id'].value;
    let colorId = form.elements['color_id'].value;
    let sizeId = form.elements['size_id'].value;
    let qteProd = form.elements['qteProd'].value;

    var quantity = parseInt(qteProd);

    let data = {
        idprod: productId,
        idcouleur: colorId,
        idtaille: sizeId,
        qte: quantity,
        dataTypeReturn: 'json'
    };

    $.ajax({
        url: path_relative_root + 'ajax_upd_panier.php',
        type: 'post',
        dataType: 'json',
        data: data,
        success: function (res) {
            if (res.error !== 0) {
                alert(res.error.msg);
            } else {
                // Suppression de l'ancien panier
                removeBasketItem(basketId);
            }
        }
    });
}

function removeBasketItem(basketId) {

    let datas = {
        action : 'del',
        panierId : basketId,
        cad  : 'non',
        pointcad : 0,
        returnHtml : "ajax"
    };

    $.ajax({
        url: path_relative_root + create_link('order_basket'),
        type: 'post',
        data: datas,
        cache: false,
        success : function (res) {
            try {
                res = $.parseJSON(res);
                let content = res.datas;

                document.querySelector('.cart_main_table.basket.dropper').innerHTML = $('.cart_main_table.basket.dropper', content).html();
                var evt = new CustomEvent("removeBasketItemCustom");
                window.dispatchEvent(evt);

            } catch (e) {
                console.error('JSON ERROR: could not parse data');
                return false;
            }
        }
    });
}

// Get product data
function getProductDetail(productId) {

    let form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + productId);

    var pcolor = form.getValue('idCouleur');
    let val_color = form.getValue(pcolor);

    let psize = form.getValue('idTaille');
    let val_size = form.getValue(psize);

    let data = {
        produitId: productId,
        colorId : val_color,
        sizeId : val_size,
        json: 'json'
    };

    $.ajax({
        url: path_relative_root + 'ajax_product_data.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {

            // Set event ID for product detail dataLayer
            if (typeof(res) == 'object') {
                pushIntoDatalayer(res.dataLayerData.productDetails);
            }
        }
    });
}

// Get Object data
function getObjectDetail(type, id, currency) {
    var impressions = data_layer_products;
    let data = {
        type: type,
        id: id,
        json: 'json'
    };


    var dataInfo = {
        event: "productImpressions",
        ecommerce: {
            currencyCode: currency,
            impressions: [impressions]
        }
    }

    $.ajax({
        url: path_relative_root + 'ajax_object_data.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {
            // Set event ID for product detail dataLayer
            if (typeof (res) == 'object') {
                dataInfo['ecommerce']['event_id'] = res.dataLayerData.event_id;
                pushIntoDatalayer(dataInfo);
            }
        }
    });
}

// Check request validity of the order return
function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

function livraisonBf() {
    var now = Math.floor(Date.now() / 1000);
    var transporteur_delay_sign = '<div class="transporteur_delay">' + Translator.translate('transporteur_delay_1_bf') + '</div>';

    if ($('#timestamp_check').length > 0) {
        now = $('#timestamp_check').attr('data-date');
    }

    // Timestamp 02/12/2024 00:01
    if (now < 1733094001) {
        $('.choix_delivery_postal.transporteur_Colissimo .transporteur_delay').html(Translator.translate('transporteur_delay_1_bf'));
        $('.choix_delivery_relais.relais.transporteur_Colissimo .transporteur_delay').html(Translator.translate('transporteur_delay_39_bf'));
        $('.choix_delivery_relais.transporteur_Livraison .transporteur_delay').html(Translator.translate( 'transporteur_delay_26_bf'));
        $('.choix_delivery_postal [data-id-transporteur="42"] #infos_livr_domicile').append(transporteur_delay_sign);
    }
}

if ($('body').hasClass('step_2') && $('.left_side.elem_tunnel').length) {
    livraisonBf();
}